export type MethodDict = typeof METHOD_DICT;
export type MethodKey = keyof MethodDict;
export type MethodValue = MethodDict[MethodKey];
export const METHOD_DICT = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
} as const;

export const LOGIN_ERROR = 'NOT_LOGGED_IN' as const;

export const REQ_TIMEOUT = 2 * 60 * 1000; // 2m

export const BASE_URL = `${__APP_BACKEND__}/backend/api`;

export const SOCKET_URL = __APP_BACKEND__;

export const SOCKET_PATH = '/backend/api/socket';
