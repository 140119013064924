import {
  APP_LOAD_FILE,
  DEAL_ADD_NEW_OUTLAY,
  DEAL_DELETE_OUTLAY,
  DEAL_EDIT_DATA,
  DEAL_EDIT_OUTLAY,
  OUTLAY_ADD_ROOM,
  OUTLAY_CHANGE_POSITION_ORDER,
  OUTLAY_CHANGE_ROOM_ORDER,
  OUTLAY_CUSTOM_POSITION_CHANGE_NAME,
  OUTLAY_DELETE_ROOM,
  OUTLAY_POSITION_CHANGE_ACT_NUMBER,
  OUTLAY_POSITION_CHANGE_PRICE_MANUAL,
  OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL,
  OUTLAY_POSITION_CHANGE_ACT_QUANTITY,
  OUTLAY_POSITION_CHANGE_UNIT,
  OUTLAY_POSITION_TURN,
  OUTLAY_RECALCULATE_VALUES,
  OUTLAY_ROOM_ADD_POSITIONS,
  OUTLAY_ROOM_CHANGE_BATHROOM_STATUS,
  OUTLAY_ROOM_CHANGE_HEIGHT,
  OUTLAY_ROOM_CHANGE_LENGTH,
  OUTLAY_ROOM_CHANGE_NAME,
  OUTLAY_ROOM_CHANGE_OPENINGS,
  OUTLAY_ROOM_CHANGE_PARAMS,
  OUTLAY_ROOM_CHANGE_WIDTH,
  OUTLAY_ROOM_DELETE_POSITION,
  OUTLAY_EDIT_PROMOTION,
  OUTLAY_EDIT_SUB_ACT,
  OUTLAY_CREATE_SUB_ACT,
  OUTLAY_ADD_PROMOTION,
  OUTLAY_REMOVE_PROMOTION,
  DEAL_SET_CLIENT_INFO,
  OUTLAY_ROOM_CHANGE_TYPE,
  OUTLAY_ADD_USER_TEMPLATE,
  OUTLAY_DELETE_USER_TEMPLATE,
} from 'store/constants';
import { SESSION_ADD_EVENT, SESSION_ARCHIVE_EVENT } from 'store/Session/constants';
import { changeOutlayId } from 'store/Deal/actions';
import { request, socket } from 'shared/utils';
import { dispatch } from 'store';
import { deleteDoc, updateDoc } from 'store/Outlay/actions';

export const reduceThunk = (
  /** @type {{ type: string, payload: Record<string, any> }} */ { type, payload = {} },
) => {
  switch (type) {
    case DEAL_EDIT_DATA: {
      return async () => {
        const { id, client = {}, ...deal } = payload.data;

        await request.patch('/deals/' + id, { ...deal, ...client });
      };
    }
    case DEAL_ADD_NEW_OUTLAY: {
      if (payload.isMasterAct) return null;
      
      return async () => {
        const outlay = payload.data;

        const data = await request.post('/outlays', outlay);
        const newId = data.outlayId;
        const oldId = payload.data.id;
        dispatch(changeOutlayId(oldId, newId, data.outlay));
      };
    }
    case DEAL_DELETE_OUTLAY: {
      return () => request.delete('/outlays/' + payload.outlayId);
    }
    case DEAL_EDIT_OUTLAY: {
      return () => request.patch('/outlays/' + payload.outlayId, payload.data);
    }
    case OUTLAY_DELETE_ROOM: {
      return () => request.delete(`/rooms/${payload.outlayId}/${payload.roomId}`);
    }
    case OUTLAY_ADD_ROOM: {
      return () => request.post('/rooms', payload.data);
    }
    case OUTLAY_ROOM_CHANGE_NAME: {
      return () => request.patch('/rooms/' + payload.roomId, payload.name
        ? { name: payload.name, roomTypeId: null }
        : { name: null });
    }
    case OUTLAY_ROOM_CHANGE_TYPE: {
      return async () => {
        const apiPayload = { roomTypeId: payload.roomType?.id || null };
        if (apiPayload.roomTypeId !== null) apiPayload.name = null;

        await request.patch('/rooms/' + payload.roomId, apiPayload);
      };
    }
    case OUTLAY_ROOM_CHANGE_BATHROOM_STATUS: {
      return () => request.patch('/rooms/' + payload.roomId, { isBathroom: payload.value });
    }
    case OUTLAY_ROOM_CHANGE_WIDTH: {
      return () => request.patch('/rooms/' + payload.roomId, { width: payload.data });
    }
    case OUTLAY_ROOM_CHANGE_HEIGHT: {
      return () => request.patch('/rooms/' + payload.roomId, { height: payload.data });
    }
    case OUTLAY_ROOM_CHANGE_LENGTH: {
      return () => request.patch('/rooms/' + payload.roomId, { length: payload.data });
    }
    case OUTLAY_ROOM_CHANGE_OPENINGS: {
      return () => request.patch('/rooms/' + payload.roomId, { openings: payload.data });
    }
    case OUTLAY_ROOM_CHANGE_PARAMS: {
      return () => request.patch('/rooms/' + payload.roomId, payload.data);
    }
    case OUTLAY_ROOM_DELETE_POSITION: {
      return () => request.delete(`/positions/${payload.id}`);
    }
    case OUTLAY_CUSTOM_POSITION_CHANGE_NAME: {
      return () => request.patch('/positions/' + payload.positionId, {
        params: { customName: payload.name },
      });
    }
    case OUTLAY_POSITION_CHANGE_ACT_NUMBER: {
      return () => request.patch('/positions/' + payload.roomPositionId, {
        params: { actNumber: payload.actNumber },
      });
    }
    case OUTLAY_POSITION_CHANGE_UNIT: {
      return () => request.patch('/positions/' + payload.positionId, {
        params: { customUnit: payload.unit },
      });
    }
    case OUTLAY_POSITION_TURN: {
      return () => request.patch('/positions/' + payload.roomPositionId, {
        params: { isTurnedOff: payload.value },
      });
    }
    case OUTLAY_ROOM_ADD_POSITIONS: {
      return () => request.post('/positions', {
        roomId: payload.roomId,
        positions: payload.roomPositions,
      });
    }
    case OUTLAY_ADD_PROMOTION: {
      return () => request.post('/promotions', payload);
    }
    case OUTLAY_REMOVE_PROMOTION: {
      return () => request.delete('/promotions/' + payload.promotionId);
    }
    case OUTLAY_EDIT_PROMOTION: {
      return () => request.patch('/promotions/' + payload.promotionId, payload.data);
    }
    case OUTLAY_POSITION_CHANGE_QUANTITY_MANUAL: {
      return () => request.patch('/positions/' + payload.roomPositionId, {
        params: { quantityManual: payload.quantity },
      });
    }
    case OUTLAY_POSITION_CHANGE_PRICE_MANUAL: {
      return () => request.patch('/positions/' + payload.roomPositionId, {
        params: { priceManual: payload.price },
      });
    }
    case OUTLAY_POSITION_CHANGE_ACT_QUANTITY: {
      return () => request.patch('/positions/' + payload.roomPositionId, {
        params: { actQuantities: payload.actQuantities },
      });
    }
    case OUTLAY_CHANGE_ROOM_ORDER: {
      return () => request.post('/rooms/reorder', payload);
    }
    case OUTLAY_CHANGE_POSITION_ORDER: {
      return () => request.post('/positions/reorder', payload);
    }
    case OUTLAY_RECALCULATE_VALUES: {
      return () => {
        const rooms = payload.rooms ?? [];
        payload.rooms = undefined;
        const promises = rooms.map((room) => request.patch(`/rooms/${room.id}`, room));

        const url = `/outlays/${payload.id}`;
        const subActUrl = `${url}/subAct`;

        const subActs = payload.subActs ?? [];
        payload.subActs = undefined;
        promises.push(...subActs.map((act) => request.patch(subActUrl, act)));

        promises.push(request.patch(url, payload));

        return Promise.all(promises);
      };
    }
    case APP_LOAD_FILE: {
      return async () => {
        const { deal, outlay, shouldOverwrite } = payload;

        const data = await request.post('/outlays/saveLoaded', { deal, outlay, shouldOverwrite });

        await window.router.navigate(`/deals/${deal.id}/outlay/${data.outlayId}`, { replace: true });
        window.location.reload();
      };
    }
    case OUTLAY_EDIT_SUB_ACT: {
      return () => request.patch(`/outlays/${payload.outlayId}/subAct`, {
        ...payload.data,
        actNumber: payload.actNumber,
      });
    }
    case OUTLAY_CREATE_SUB_ACT: {
      return () => request.post(`/outlays/${payload.subAct.outlayId}/subAct`, payload.subAct);
    }
    case DEAL_SET_CLIENT_INFO: {
      return async () => {
        const { dealId, data } = payload;
        if (!dealId) return;

        await request.patch('/sense/' + dealId, data || {});
      };
    }
    case OUTLAY_ADD_USER_TEMPLATE: {
      return () => request.post('/templates', payload.userTemplate);
    }
    case OUTLAY_DELETE_USER_TEMPLATE: {
      return () => request.delete(`/templates/${payload.userTemplateId}`);
    }
    case SESSION_ADD_EVENT: {
      return () => {
        const event = payload.event;

        if (event.type === 'docUrl') return dispatch(updateDoc({
          id: event.docId,
          fileUrl: event.fileUrl,
          signUrl: event.signUrl,
        }));

        if (event.type !== 'doc') return;

        if (!event.docType.includes('ContCancelDoc'))
            return dispatch(updateDoc(event));

        if (event.signStatus === 'signed')
          return dispatch(deleteDoc(event.parameters.docId));
      };
    };
    case SESSION_ARCHIVE_EVENT: {
      return () => socket.emit('event', { id: payload.eventId, isArchived: true });
    }
    default:
      return null;
  }
};
