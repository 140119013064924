/** Rotate by one in-place from lh_idx to rh_idx, effectively moving an element from lh_idx to rh_idx */
export const moveWithin = <T>(array: T[], lh_idx?: number, rh_idx?: number): T[] => {
  lh_idx ??= 0;
  rh_idx ??= array.length - 1;
  if (lh_idx === rh_idx)
    return array;

  const item = array[lh_idx];
  if (lh_idx > rh_idx)
    for (let idx = lh_idx; idx > rh_idx; idx--)
      array[idx] = array[idx - 1];
  else
    for (let idx = lh_idx; idx < rh_idx; idx++)
      array[idx] = array[idx + 1];
  array[rh_idx] = item;

  return array;
};
