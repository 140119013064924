import { legacy_createStore as createStore } from 'redux';

import rootReducer from './reducers';

/** @type {import('redux').Store} */
export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
    // actionSanitizer: (action) => {
    //   if (action.type === 'DEAL_CHANGE_SELECTED' && action.payload?.data?.deal?.brand) action.payload.data.deal.brand = {
    //     ...action.payload.data.deal.brand,
    //     logo: action.payload.data.deal.brand.logo && '<BRAND_LOGO>',
    //   };
  
    //   return action;
    // },
    // stateSanitizer: (state) => {
    //   if (state.deal.selectedDeal?.brand) state.deal.selectedDeal.brand = {
    //     ...state.deal.selectedDeal.brand,
    //     logo: state.deal.selectedDeal.brand.logo && '<BRAND_LOGO>',
    //   };
  
    //   return state;
    // },
  }),
);

export const dispatch = store.dispatch;
