import { ReactNode } from 'react';
import { message, notification } from 'antd';

export * from './alert';

interface NoteOptions {
  type?: 'message' | 'notification';
  method?: 'open' | 'success' | 'error' | 'info' | 'warning';
  content?: string;
  duration?: number;
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  title?: string;
  btn?: ReactNode;
  [key: string]: any;
}

const FEEDBACK_TYPES = { message, notification };

const note = (
  key: string,
  {
    type = 'message',
    method = 'error',
    content = 'Сообщение',
    duration = 4,
    placement = 'topRight',
    title = 'Заголовок',
    ...restProps
  }: NoteOptions
) => {
  const noteApi = FEEDBACK_TYPES[type];
  const noteMethod = noteApi[method];

  const onClick = type === 'notification' ? undefined : () => message.destroy(key);
  const style = type === 'notification' ? undefined : { top: 0, right: 0 };

  return noteMethod({
    content,
    description: content,
    message: title,
    key,
    duration,
    placement,
    onClick,
    style,
    ...restProps,
  });
};

export default note;
